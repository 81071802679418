.apps {
    width: 100%;
    background-color: #101014;
    padding: 6em 1em;
    overflow: hidden;
}

.apps h2 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.apps h2::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-right: 10px;
}

.apps h2::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-left: 10px;
}

.apps h3 {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 3.5em;
    font-weight: 600;
    text-align: center;
    animation: neon 5s infinite ease-in-out;
}

.apps hr {
    width: 50%;
    background: #39a9ea;
    height: 3px;
    margin: 5em auto;
    box-shadow: 0 0 10px 2px  rgb(57, 169, 234, 0.7);
}

@media (max-width: 768px) {
    .apps h2 {
        font-size: 1.5em;
    }

    .apps h3 {
        font-size: 1.8em;
    }
}