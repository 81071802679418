.footer {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    box-shadow: 0 -5px 20px  #39a9ea;
}

.footer img {
    aspect-ratio: attr(width) / attr(height);
}

.footer hr {
    width: 60%;
    background: #a1a1a1;
    height: 3px;
    margin: 1em 0;
    padding: 0;
}

.footer nav {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    transition: all 600ms ease-in-out;
}

.footer nav main {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.footer a {
    font-family: 'Audiowide', cursive;
	color: #fff;
	margin: 1rem;
	text-decoration: none;
    border-bottom: solid 2px transparent;
	position: relative;
}

.footer a.selected {
    border-bottom: solid 2px #fff;
    animation: neon 5s infinite;
}

.footer a:after{
	background: none repeat scroll 0 0 transparent;
	bottom: -0.1em;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	right: 50%;
	position: absolute;
	background: #fff;
	transition: width 0.6s ease 0s, left 0.6s ease 0s, right 0.6s ease 0s;
	width: 0;
    animation: neon 5s infinite;
}

.footer a:hover:after {
	width: 100%;
	left: 0;
	right: 0;
    animation: neon 5s infinite;
}

.footer section {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer section button {
    margin: 0 1em;
}

.footer p {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 1em;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .footer {
        padding-bottom: 5em;
    }

    .footer img {
        width: 100%;
    }

    .footer nav {
        flex-direction: column;
        justify-content: center;
    }

    .footer nav main {
        width: 100%;
    }
}