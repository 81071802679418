.about {
    width: 100%;
    padding: 1em;
    padding-bottom: 0;
    overflow: hidden;
}

.about h2 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.about h2::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin-right: 10px;
}

.about h2::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin-left: 10px;
}

.about .card {
    background: none;
    padding: 0.5em;
    font-weight: 600;
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
}

.about .card .text {
    align-self: flex-end;
}

.about .card p {
    width: 65%;
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 2.7em;
    font-weight: 100;
}

.vector {
    aspect-ratio: attr(width) / attr(height);
    margin-bottom: 0;
    filter: drop-shadow(10px 5px 20px  rgb(57, 169, 234, 0.7));
    display: flex;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .about h2 {
        font-size: 1.5em;
    }

    .about .card p {
        width: 100%;
        font-size: 1.5em;
    }
}